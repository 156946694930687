import { onPatch, types } from "mobx-state-tree";

const Ui = types
  .model({
    mobileMenuOpened: false,
    hoverMenu: "",
    navigationSignUpBtn: false,
    caseStudyLeadOpen: "",
    aboutVideo: false
  })
  .actions((self) => {
    let disposer = null;

    function toggleMobileMenu(bool) {
      self.mobileMenuOpened = bool;
    }

    function toggleNavigationSignUpBtn(bool) {
      self.navigationSignUpBtn = bool;
    }

    function setHoverMenu(string) {
      self.hoverMenu = string;
    }

    function afterCreate() {
      disposer = onPatch(self, () => {});
    }

    function beforeDetach() {
      disposer();
    }

    function toggleCaseStudyLead(str) {
      self.caseStudyLeadOpen = str;
    }

    function toggleAboutVideo(bool) {
      self.aboutVideo = bool;
    }

    return {
      afterCreate,
      beforeDetach,
      toggleMobileMenu,
      setHoverMenu,
      toggleNavigationSignUpBtn,
      toggleCaseStudyLead,
      toggleAboutVideo
    };
  });

export default Ui;
