import { types as t } from "mobx-state-tree";
import Ui from "./ui";

const State = t.model({ ui: Ui });

const state = State.create({
  ui: {}
});

export default state;
